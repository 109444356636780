<template>
  <div>
    <b-card
      class="mb-0"
      no-body
      :style="{ height: card_height, overflow: 'auto' }"
    >
      <b-card-body class="px-0" :class="true ? 'pt-0' : ''">
        <b-table
          small
          :filter="searchQuery"
          hover
          :fields="fields"
          :items="data"
          responsive
          :sortBy="'insert_date'"
          show-empty
          :empty-text="lang('t_noRecord')"
          :sort-desc.sync="sortDesc"
          :busy="data.length == 0"
          @row-dblclicked="loadSound"
        >
          <template #empty="scope">
            <b-container class="mt-5">
              <b-row>
                <b-col class="text-center text-muted mt-50">
                  <span>{{ scope.emptyText }}</span>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <template #cell(queuename)="data">
            <b-badge variant="light-primary">
              {{
                queues.find((e) => e.internal_name == data.value)
                  ? queues.find((e) => e.internal_name == data.value)
                      .display_name
                  : data.value
              }}
            </b-badge>
          </template>
          <template #cell(call_direction)="data">
            <b-icon
              icon="telephone-inbound"
              v-if="data.value == 'inbound'"
              :class="data.item.RecordingFile ? 'text-success' : ''"
            ></b-icon>
            <b-icon
              icon="telephone-outbound"
              v-else-if="data.value == 'outbound'"
              :class="data.item.RecordingFile ? 'text-success' : ''"
            ></b-icon>
            <b-icon
              icon="telephone-outbound"
              v-else-if="data.value == 'dialer'"
              :class="data.item.RecordingFile ? 'text-success' : ''"
            ></b-icon>
            <b-icon icon="chat-dots" v-else-if="data.value == 'text'"></b-icon>
            <b-icon
              icon="card-checklist"
              v-else-if="data.value == 'manual'"
            ></b-icon>
            <span v-else> {{ data.value }}</span>
          </template>
          <template #cell(contact_number)="data">
            <a href="#" @click="start_call(data.value)">
              {{ GetRemoteNumber(data.value) }}</a
            >
          </template>
          <template #cell(note)="data">
            <b-button
              variant="light-secondary"
              v-if="data.value.length > 0"
              @click="openNote(data.value)"
              class="py-50 px-50"
            >
              <i class="bi bi-sticky font-medium-1"></i>
            </b-button>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              boundary="viewport"
              dropleft
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              toggle-class="px-0 py-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :disabled="
                  data.item && data.item.note && data.item.note.length <= 0
                "
                @click="openNote(data.item.note)"
              >
                <b-icon icon="card-text"></b-icon>
                <span class="align-middle ml-50">{{ lang("t_note") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item && data.item.RecordingFile != ''"
                @click="loadSound(data.item)"
              >
                <b-icon icon="play-fill"></b-icon>
                <span class="align-middle ml-50">{{
                  lang("t_recordingFile")
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(rate)="data">
            <b-progress
              show-value
              :value="(data.value * 100) / extra.total_finish_code_counts"
              :max="100"
              :variant="
                parseInt((data.value / extra.total_finish_code_counts) * 100) >
                40
                  ? 'success'
                  : 'warning'
              "
            />
          </template>
          <template #cell(Productivity)="data">
            <b-progress
              show-value
              :value="data.value"
              :max="100"
              :variant="parseInt(data.value) > 40 ? 'success' : 'warning'"
            />
          </template>
          <template #cell(category)="data">
            <center>
              <b-badge :variant="'light-secondary'">
                {{ data.value }}
              </b-badge>
            </center>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <b-modal
      hide-footer
      body-class="px-0 py-0"
      v-model="is_fullscreen"
      size="xl"
      centered
      :title="lang(title)"
    >
      <b-card
        class="mb-0"
        no-body
        :style="{ height: '70vh', overflow: 'auto' }"
      >
        <b-card-body class="px-0">
          <b-card-text class="d-flex ml-2 mr-50">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                :placeholder="lang('t_search')"
              ></b-form-input>
            </b-input-group>

            <!-- <b-button
              size="sm"
              class="px-50 py-50 ml-50"
              variant="flat-secondary"
            >
              <b-icon icon="download"></b-icon>
            </b-button>
            <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
              <b-icon icon="funnel"></b-icon>
            </b-button>
            <b-button
              @click="is_fullscreen = !is_fullscreen"
              size="sm"
              class="px-50 py-50"
              variant="flat-secondary"
            >
              <b-icon
                :icon="getFullScreen ? 'fullscreen' : 'fullscreen-exit'"
              ></b-icon>
            </b-button> -->
          </b-card-text>
          <b-table
            :filter="searchQuery"
            hover
            :fields="fields"
            :items="data"
            responsive
            :sortBy="'insert_date'"
            show-empty
            :empty-text="lang('t_noRecord')"
            :sort-desc.sync="sortDesc"
            :busy="data.length == 0"
          >
            <template #empty="scope">
              <b-container class="mt-5">
                <b-row>
                  <b-col class="text-center text-muted mt-50">
                    <span>{{ scope.emptyText }}</span>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #cell(queuename)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(call_direction)="data">
              <b-icon
                icon="telephone-inbound"
                v-if="data.value == 'inbound'"
              ></b-icon>
              <b-icon
                icon="telephone-outbound"
                v-if="data.value == 'outbound'"
              ></b-icon>
              <b-icon
                icon="telephone-outbound"
                v-if="data.value == 'dialer'"
              ></b-icon>
            </template>
            <template #cell(contact_number)="data">
              <a href="#" @click="start_call(data.value)">
                {{ GetRemoteNumber(data.value) }}</a
              >
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                boundary="viewport"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.note.length > 0"
                  @click="openNote(data.item.note)"
                >
                  <b-icon icon="card-text"></b-icon>
                  <span class="align-middle ml-50">{{ lang("t_note") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.item.RecordingFile != ''"
                  @click="openAudio(data.item)"
                >
                  <b-icon icon="play-fill"></b-icon>
                  <span class="align-middle ml-50">{{
                    lang("t_recordingFile")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(rate)="data">
              <b-progress
                show-value
                :value="(data.value * 100) / extra.total_finish_code_counts"
                :max="100"
                :variant="
                  parseInt(
                    (data.value / extra.total_finish_code_counts) * 100
                  ) > 40
                    ? 'success'
                    : 'warning'
                "
              />
            </template>
            <template #cell(Productivity)="data">
              <b-progress
                show-value
                :value="data.value"
                :max="100"
                :variant="parseInt(data.value) > 40 ? 'success' : 'warning'"
              />
            </template>

            <template #cell(category)="data">
              <center>
                <b-badge :variant="'light-secondary'">
                  {{ data.value }}
                </b-badge>
              </center>
            </template>
          </b-table>
        </b-card-body>

        <b-card-footer class="py-75 border-0">
          <div class="d-flex justify-content-between d-flex align-items-center">
            <div class="d-flex align-items-center mb-0">
              <!-- <span class="text-nowrap"> Showing 1 to </span> -->
              <b-form-select
                size="sm"
                v-model="perPage"
                :options="['10', '25', '50']"
                class="mr-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap text-muted font-small-3">
                {{
                  lang("t_total") +
                  " " +
                  data.length +
                  " " +
                  lang("t_row").toLowerCase()
                }}
              </span>
            </div>

            <b-pagination
              size="sm"
              class="mb-0"
              v-model="currentPage"
              :per-page="perPage"
              align="right"
              :total-rows="data.length"
              first-number
              last-number
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="16" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="16" />
              </template>
            </b-pagination>
          </div>
        </b-card-footer>
      </b-card>
    </b-modal>
    <b-row
      v-if="title == 't_callHistory'"
      :class="displaySound ? 'd-block' : 'd-none'"
      style="
        position: fixed;
        bottom: 0;
        z-index: 9999;
        left: 0;
        width: 100vw;
        margin-left: 0;
        margin-right: 0;
      "
    >
      <b-col class="px-0">
        <b-card class="mb-0">
          <b-card-text>
            <b-row class="mx-0">
              <b-col
                class="d-flex align-items-center justify-content-center px-0"
              >
                <b-dropdown
                  boundary="viewport"
                  v-if="wavesurfer"
                  class="mr-50 ml-2"
                  :text="wavesurfer.getPlaybackRate() + 'x'"
                  variant="flat-secondary"
                >
                  <b-dropdown-item @click="setSoundSpeed(0.25)">
                    0.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.5)">
                    0.5x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.75)">
                    0.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1)">
                    1x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.25)">
                    1.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.5)">
                    1.50x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.75)">
                    1.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(2)">
                    2x
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipBackIcon" />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon rounded-circle mx-50"
                  @click="
                    () => {
                      wavesurfer.playPause();
                    }
                  "
                >
                  <feather-icon
                    v-if="wavesurfer"
                    size="20"
                    :icon="wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'"
                  />
                </b-button>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipForwardIcon" />
                </b-button>
                <span style="width: 3rem" class="ml-3">
                  {{ `${formatTime(parseInt(currentTime))}` }}
                </span>
                <b-col class="cursor-pointer" id="chwaveform"></b-col>
                <span
                  style="width: 3rem"
                  v-if="wavesurfer && wavesurfer.decodedData"
                >
                  {{
                    `${formatTime(parseInt(wavesurfer.decodedData.duration))}`
                  }}
                </span>
                <b-button
                  @click="closeSound"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle ml-5"
                >
                  <feather-icon size="20" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>

            <!-- <div id="hover"></div> -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BContainer,
  BListGroup,
  BListGroupItem,
  BCardSubTitle,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgressBar,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BCardFooter,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BIcon,
  BFormSelect,
  BModal,

} from "bootstrap-vue";

import "animate.css";
import { $themeColors } from '@themeConfig'
export default {
  components: {
    BModal,
    BPagination,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BIcon,
    BContainer,
    BProgressBar,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTable,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    VBTooltip,
    BDropdown,
    BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    title: {
      type: String,
      default: () => (''),
    },
    sub_title: {
      type: String,
      default: () => (''),
    },

    data: {
      type: Array,
      default: () => ([]),
    },
    extra: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
    sortDesc: {
      type: Boolean,
      default: () => (false),
    },
    no_header: {
      type: Boolean,
      default: () => (false),
    },
    card_height: {
      type: String,
      default: () => ('20vh'),
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      queues: window.queues,
      currentPage: 1,
      perPage: "10",
      searchQuery: "",
      is_fullscreen: false,
      wavesurfer: null,
      displaySound: false,
      currentTime: 0,
    };
  },
  methods: {
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },
    loadSound(item) {
      if (item.RecordingFile && globalThis.permissions.other.includes('agent_display_sound')) {
        let url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;
        console.log(item);
        var myDiv = document.getElementById(`chwaveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.wavesurfer = globalThis.WaveSurfer.create({
          height: 60,

          container: `#chwaveform`,
          waveColor: '#F0F0F1',
          progressColor: $themeColors.primary,
          url: url,
          audioRate: 1,
          barWidth: 4,
          barGap: 3,
          barRadius: 8,
        })
        console.log("wavesurfer", this.wavesurfer)

        this.wavesurfer.once('interaction', () => {
          console.log("interaction")
          this.wavesurfer.play()
        })
        this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);

        this.displaySound = true;
      }
    },
    closeSound() {
      this.displaySound = false;
      var myDiv = document.getElementById(`chwaveform`);
      myDiv.innerHTML = null;
      this.wavesurfer = null;
      this.currentTime = 0;
    },

    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    start_call(phone) {
      globalThis.LayoutContentRendererDefault.preview_call_request(phone);
    },
    GetRemoteNumber(phone) {
      if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
        return globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
      } else {
        return phone.split(' ').join('').replace(/\D+/g, '');
      }
    },
  },
  mounted: async function () {
    let _self = this;
    setTimeout(() => {
      this.queues = globalThis.queues;
    }, 1000);
  },
  computed: {

    getFullScreen() {
      return true;

      if (!this.is_fullscreen) {
        return false;
      }
      console.log("this.is_fullscreen", this.is_fullscreen)
      console.log("this.title", this.title)
      console.log("this.title", this.sub_title)
      if (this.title == '' && this.sub_title == '') {
        console.log("GELDİ")
        return false;
      }
    },
  },
}

  </script>
