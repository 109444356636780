<template>
  <b-card class="text-center">
    <b-avatar class="mt-1 mb-1" :variant="`light-${color}`" size="72">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span class="font-weight-bold">{{ statisticTitle }}</span>
      <br />
      <label>{{ statisticSubTitle }}</label>
    </div>
  </b-card>
</template>

<script>
import { BRow, BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BRow,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticSubTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
